// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-coming-soon-js": () => import("./../../../src/pages/coming-soon.js" /* webpackChunkName: "component---src-pages-coming-soon-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-news-template-js": () => import("./../../../src/pages/news-template.js" /* webpackChunkName: "component---src-pages-news-template-js" */),
  "component---src-pages-people-alt-1-js": () => import("./../../../src/pages/people-alt1.js" /* webpackChunkName: "component---src-pages-people-alt-1-js" */),
  "component---src-pages-people-js": () => import("./../../../src/pages/people.js" /* webpackChunkName: "component---src-pages-people-js" */),
  "component---src-pages-people-template-js": () => import("./../../../src/pages/people-template.js" /* webpackChunkName: "component---src-pages-people-template-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-template-news-template-js": () => import("./../../../src/template/news-template.js" /* webpackChunkName: "component---src-template-news-template-js" */),
  "component---src-template-people-partner-template-js": () => import("./../../../src/template/peoplePartnerTemplate.js" /* webpackChunkName: "component---src-template-people-partner-template-js" */),
  "component---src-template-people-template-js": () => import("./../../../src/template/people-template.js" /* webpackChunkName: "component---src-template-people-template-js" */),
  "component---src-template-project-template-js": () => import("./../../../src/template/project-template.js" /* webpackChunkName: "component---src-template-project-template-js" */)
}

